/**
 * Allows css background images to be defined by srcset
 * https://aclaes.com/responsive-background-images-with-srcset-and-sizes/
 *
 * @author Alexandar Cales
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @class ResponsiveBackgroundImage
 */
class ResponsiveBackgroundImage {
  constructor(element) {
    this.element = element
    this.img = element.querySelector('img')
    this.src = ''

    this.img.addEventListener('load', () => {
      this.update()
    })

    if (this.img.complete) {
      this.update()
    }
  }

  update() {
    let src =
      'undefined' !== typeof this.img.currentSrc
        ? this.img.currentSrc
        : this.img.src
    if (this.src !== src) {
      this.src = src
      this.element.style.backgroundImage = 'url("' + this.src + '")'
    }
  }
}

let elements = document.querySelectorAll('[data-responsive-background-image]')
for (let i = 0; i < elements.length; i++) {
  new ResponsiveBackgroundImage(elements[i])
}
