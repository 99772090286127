/**
 * Adds a .focus class to wrapping parent element of an input which has class .input-block.
 *
 * TODO: add listener to body or window and use event delegation.
 *
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @returns void
 */
document.querySelectorAll('.v1-m-inputBlock input').forEach(function (input) {
  input.addEventListener('focus', function () {
    this.parentNode.classList.add('-is-focused')
  })
  input.addEventListener('blur', function () {
    if ('' == this.value) {
      this.parentNode.classList.remove('-is-focused')
    } else {
      this.parentNode.classList.add('-is-focused')
    }
  })
})
