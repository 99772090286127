/**
 * Conditionally load the picturefill polyfill
 *
 * https://philipwalton.com/articles/loading-polyfills-only-when-needed/
 * https://github.com/scottjehl/picturefill/issues/677#issuecomment-289569292
 *
 * @author Philip Walton
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 */

;(function () {
  if (typeof window !== 'undefined' && !('picturefill' in window)) {
    const image = document.createElement('img')
    if (
      !('srcset' in image) ||
      !('sizes' in image) ||
      !window.HTMLPictureElement
    ) {
      // load
      console.log('picturefill loading')
      script.src = window.ADRS.SOURCE_PATH + '/picturefill.min.js'
      document.head.appendChild(script)
    }
  }
})()
