/**
 * Scale io graphs on small(ish) screens.
 *
 * TODO: Refactor, change graphic lib with that is able to scale dynamically.
 *
 * @description Minimum Viable Solution
 * @requires jQuery
 * @requires u-debounce.js
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @returns {void}
 */
;(function ($) {
  var wrapper = $('.v1-o-pageModel__content.-is-output')
  var mainTable = $('#main_table')

  if (wrapper.length) {
    var wrapperData = {
      size: {
        width: wrapper.width(),
        height: wrapper.height()
      }
    }
    var $el = $('.stage')
    var elHeight = $el.outerHeight()
    var elWidth = $el.outerWidth()

    var debounceDoScale = debounce(function () {
      wrapperData = {
        size: {
          width: wrapper.width(),
          height: wrapper.height()
        }
      }
      doScale(null, wrapperData)
    }, 250)

    doScale(null, wrapperData)
    window.addEventListener('resize', debounceDoScale)
  }

  /**
   * @param {object} event object
   * @param {object} ui object
   */
  function doScale(event, ui) {
    var scale = Math.min(ui.size.width / elWidth, ui.size.height / elHeight)

    var $offset = $('.v1-o-pageModel__outputMenu')
    var offsetHeight = $offset.outerHeight()

    if (1 > scale) {
      console.log('ui.size.height: ' + ui.size.height)
      console.log('elHeight: ' + elHeight)
      console.log('scale: ' + scale)
      console.log('elWidth * scale: ' + elWidth * scale)
      console.log('offsetHeight: ' + offsetHeight)
      $el.css({
        // "margin-top": -offsetHeight / 4,
        'margin-bottom': -offsetHeight / 2,
        transform:
          'translate(-50%, -' + offsetHeight / 2.5 + 'px) scale(' + scale + ')',
        left: (elWidth * scale) / 2
      })
    } else {
      $el.removeAttr('style')
      $('.navigation').removeAttr('style')
    }
  }
})(jQuery)
