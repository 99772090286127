/**
 * @author David Walsh
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @description Debounce function, attached to window so that it will be available outside of browserify. https://davidwalsh.name/javascript-debounce-function
 * @version 0.0.1
 * @param {*} func
 * @param {*} wait
 * @param {*} immediate
 * @returns function
 */

window.debounce = function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this
    var args = arguments
    var later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}
