/**
 * main-public.js -> main-public-min.js
 *
 * @version 0.0.3
 * @requires jQuery
 */

// Path of the current running script for realative path references
window.ADRS = window.ADRS || {}
window.ADRS.SOURCE_PATH = document.currentScript.src.replace(/\/[^\/]+$/, '')

// JS detection
import './dev/u-jstests.js'
// test for scrset support & picturefill polyfill
import './dev/u-picturefill-pollyfill.js'

// Main navigation
import './dev/c-menu-nav.js'

// Lity lightbox
// import './vendor/lity-3.0/dist/lity.js'
import './vendor/lity-fork.js'

// Navgoco list accordians
import './vendor/jquery.navgoco.min'

// Tabbed component using Tabby.js
import './dev/u-tabs.js'

// Project Utilities
import './dev/u-elementHasOverflow.js' // jQuery plugin to test if an element has X or Y scrollbars/overflow
import './dev/u-srcsetBgImages.js' // Enable background images using srcset
import './dev/u-debounce.js' // Debounce function
import './dev/u-input-focus.js' // login form
import './dev/u-alertDismiss.js' // Dismissable alerts
//import './dev/u-scroll-to-heading.js' // Used in Componenet Library, could be used elsewhere.
import './dev/p-scale-io.js' // Used in graphing pages of models, on the front end as it is used in IO models.

import scrollToTop from './dev/u-scroll-to-top.js' // Scroll to top of page
scrollToTop({
  bgColor: 'var(--theme-primary, red)',
  iconFillColor: 'var(--theme-white, white)',
  xOffset: '1em',
  yOffset: '7em',
  scrollTrigger: 0.5
})

// Page specific scripts
import './dev/p-models-landing.js'
