/**
 * Scroll to top.
 * Add a scroll-to-top link to the bottom of the page.
 *
 * @author bnjmnRsh | bnjmnRsh@gmail.com
 * @version 0.0.1
 * @license MIT
 * @function uScrollToTop
 * @param {object} args - The arguments.
 * @param {string} args.targetEl - The element to target for placement of scroll-to-top link in the DOM.
 * @param {string} args.id - The css ID of the scroll-to-top link.
 * @param {string} args.classBase - The css class of the scroll-to-top link.
 * @param {string} args.classModifier - The css modifyer class of the scroll-to-top link when revealed.
 * @param {string} args.icon - The svg icon markup for the scroll-to-top link.
 * @param {string} args.bgColor - The background color in any valid CSS color scheme.
 * @param {string} args.iconFillColor - The icon fill color in any valid CSS color scheme (only effects the default icon).
 * @param {string} args.widthHeight - The width-height of the Scroll To Top link.
 * @param {string} args.xOffset - The x offset (from right) defaults to 0.5em.
 * @param {string} args.yOffset - The y offest (from bottom) defaults to 10%.
 * @param {number} args.scrollTrigger - Adjust when link -is-revealed, multiplier of window.innerHeight defaults to 0.25.
 * @param {boolean} args.cssOveride - Overide the default css with your own in your stylesheet.
 */
export default function scrollToTop(args = {}) {
  let {
    targetEl,
    id,
    classBase,
    classModifier,
    iconFillColor,
    icon,
    bgColor,
    widthHeight,
    xOffset,
    yOffset,
    scrollTrigger,
    cssOveride
  } = Object.assign(
    {
      targetEl: 'footer:last-of-type',
      id: '#stt',
      classBase: 'scroll-to-top',
      classModifier: '-is-revealed',
      iconFillColor: 'white',
      icon: '',
      bgColor: 'red',
      widthHeight: '40px',
      xOffset: '.5em',
      yOffset: '10%',
      scrollTrigger: 0.25,
      cssOveride: false
    },
    args
  )

  // Set the location of the el to append to
  const target = document.querySelector(targetEl)
  if (!target) return

  // Assemble icon
  if (!icon) {
    icon = `<svg class="icon-chevron-up" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16"><polygon fill="${iconFillColor}" points="8,2.8 16,10.7 13.6,13.1 8.1,7.6 2.5,13.2 0,10.7 "/></svg>`
  }
  // Create the scroll-to-top link.
  const nStt = document.createElement('a')
  nStt.id = id.slice(1)
  nStt.classList.add(classBase)
  nStt.innerHTML = icon
  nStt.href = '#'
  nStt.setAttribute('aria-description', 'Scroll to top of page')
  target.prepend(nStt)

  // Create default css.
  const nStyle = document.createElement('style')
  const sStyles = `
#stt {
  position: fixed;
  right: 0;
  bottom: ${yOffset};
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 0;
  margin: ${xOffset};
  overflow: none;
  color: inherit;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  outline: 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
  visibility: hidden;
  cursor: grab;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0, 1);
  -webkit-tap-highlight-color: none;
}
#stt.-is-revealed {
  width: ${widthHeight};
  height: ${widthHeight};
  background-color: ${bgColor};
  visibility: visible;
  opacity: 1;
}
#stt.icon-chevron-up {
  padding-bottom: .3em;
}
`
  if (!cssOveride) {
    nStyle.innerHTML = sStyles
    document.head.prepend(nStyle)
  }

  /**
   * show-hide the stt link
   * Based on window.scrollY and window.innerHeight
   *
   * @param {event} e - event
   */
  const sttShowHide = function (e) {
    const el = document.querySelector(id)
    if (window.scrollY > window.innerHeight * scrollTrigger) {
      el.classList.add('-is-revealed')
    } else {
      el.classList.remove('-is-revealed')
    }
  }
  addEventListener('scroll', sttShowHide)
}
