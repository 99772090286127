// TODO: Refactor using vanilla js

/**
 * @discription Detect if we in a large viewport
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.2
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 * @param {string} medBrkptLg // selector string
 */
function mediaBreakpointLg(medBrkptLg) {
  // Though open and closing the hamburger will reset the tabindex
  // we need to keep things accessable and detect viewport changes and reset tabindex accordingly

  if (medBrkptLg.matches) {
    // console.log('Breakpoint lg')
    navTopMenusTabindex(0)
    navSubMenusTabindex(-1)

    // Unbind the return to hamburger .keydown event from the last link node
    unbindLastMenuItemKeydown()

    // rebind submenu keydown event which closes submenues
    submenuTabNavigation()
    $('body').removeClass('-is-noScroll')
    $('#topNavBar').removeClass('-is-visible')
    $('#topNavBar').addClass('-is-brkpt_lg')
  } else {
    // top menu items inaccessable (dont want to tab to hidden items)
    navTopMenusTabindex(-1)
    navSubMenusTabindex(-1)
    $('#topNavBar').removeClass('-is-brkpt_lg')

    // Reset the click handelers
    // smallViewportMenuModalTabindex();
  }
}

/**
 * @discription Toggles the menu when the user clicks the 'hamburger'
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 */
function hamburgerToggle() {
  // toggle the atributes
  $('#topNavBarControl').toggleClass('-is-expanded')
  if ('true' === $('#topNavBarControl').attr('aria-expanded')) {
    $('#topNavBarControl').attr('aria-expanded', 'false')
  } else {
    $('#topNavBarControl').attr('aria-expanded', 'true')
  }

  // open the menu
  $('#topNavBar').toggleClass('-is-visible')

  // prevent the background from scrolling behind the modal
  $('body').toggleClass('-is-noScroll')

  /// Here we are making the menu elements in/accessable to tabindex
  // based on if the hamburger menu is open or closed
  if ($('#topNavBar').hasClass('-is-visible')) {
    // top & sub menu items accessable
    navTopMenusTabindex(0)
    navSubMenusTabindex(0)
    loginLinksTabindex(-1)

    // console.log('menu open');
  } else {
    // top & sub menu items inaccessable
    navTopMenusTabindex(-1)
    navSubMenusTabindex(-1)
    loginLinksTabindex(0)

    // console.log('menu closed');
  }
}

/**
 * @discription Collapse any open menus when a user clicks outside of them
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 */
function closeSubmenusOnClick() {
  $(document).on('click', function () {
    // prevent clicks from within a container from closing the container
    $('.v1-m-navContainer').on('click', function (event) {
      event.stopImmediatePropagation()
    })

    // Close the rest
    $('.-is-dropDown')
      .next('.v1-m-navContainer.-is-visible')
      .slideUp('fast')
      .toggleClass('-is-visible')
      .prev('.-is-dropDown')
      .attr('aria-expanded', 'false')
  })
}

/**
 * @discription Handel click events for top level menus
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 */
function menusDropDownsClickHandler() {
  $('.-is-dropDown').on('click', function (e) {
    if ('true' === $(this).attr('aria-expanded')) {
      $(this).attr('aria-expanded', 'false')
    } else {
      $(this).attr('aria-expanded', 'true')
    }

    // prevent default behavior of links on top of dropdown
    e.preventDefault()

    // Slide up/down the submenus
    $(this)
      .next('.v1-m-navContainer')
      .slideToggle('fast', function () {
        if ($(this).is(':visible')) {
          // because jquery slideToggle resets the container to block rather then flex
          $(this)
            .css('display', 'flex')
            .toggleClass('-is-visible')
            .removeClass('-is-overflowY')

          // Append shadow to bottom of scrolling economic models div if needed
          // if ($(this).hasOverflowY()) {
          //   $(this).addClass('-is-overflowY')
          // }
        }

        // toggel the tabindex on sub-menus - fix for wide displays?
        $('.v1-m-navContainer__list a').attr(
          'tabindex',
          function (index, attr) {
            return -1 == attr ? null : 0
          }
        )
      })
  })
}

/**
 * @discription  Prevent tabbing into elements behind the modal only when the hamburger menu is open
 */
function smallViewportMenuModalTabindex() {
  const lastTopLevelMenuItem = $(
    '#topNavBar.-is-visible .v01-o-navBar__menu > li:last-child .v1-m-navContainer'
  )

  // This keydown binding is destroyed when switching to larger viewport in mediaBreakpointLg()
  $('#topNavBar.-is-visible .v01-o-navBar__menu > li:last-child').keydown(
    function (e) {
      if (lastTopLevelMenuItem.hasClass('-is-visible')) {
        // Last tab is open
        $(
          '#topNavBar.-is-visible .v01-o-navBar__menu > li:last-child li:last-child a'
        ).keydown(function (e) {
          tabReturnToHamburger(e)
        })
      } else if (9 == e.which) {
        tabReturnToHamburger(e)
      }
    }
  )

  /**
   * @param {object} e event object
   */
  function tabReturnToHamburger(e) {
    if (9 == e.which && e.shiftKey) {
      // do nothing
    } else if (9 == e.which) {
      $('#mainNav #topNavBarControl').focus()
      e.preventDefault()

      // console.log('return focus to hamburger');
    }
  }
}

/**
 * @discription Handels tabbing in and out of submenus
 */
function submenuTabNavigation() {
  $('.v1-m-navContainer > .-is-tabGroup:last-of-type li:last-child a').keydown(
    function (e) {
      if (9 == e.which && e.shiftKey) {
        // do nothing
      } else if (9 == e.which) {
        // console.log('SUBMENU: last element tabbed out');
        // close the container
        $('.v1-m-navContainer.-is-visible')
          .slideUp('fast')
          .toggleClass('-is-visible')
          .prev('.-is-dropDown')
          .attr('aria-expanded', false)
      }
    }
  )
  $(
    '.v1-m-navContainer > .-is-tabGroup:first-of-type li:first-child a'
  ).keydown(function (e) {
    if (9 == e.which && e.shiftKey) {
      // console.log('SUBMENU: first element reverse-tabbed out');
      // close the container
      $('.v1-m-navContainer.-is-visible')
        .slideUp('fast')
        .toggleClass('-is-visible')
        .prev('.-is-dropDown')
        .attr('aria-expanded', false)
    } else if (9 == e.which) {
      // do nothing
    }
  })
}

/**
 * @discription Fix header nav to top when scrolling up (large displays)
 */
function fixHeaderNav() {
  $(window).scroll(function () {
    const headertop = $('.v1-o-topHeader').outerHeight(true)
    const scrolltop = $(window).scrollTop()
    if (scrolltop >= headertop) {
      $('.v1-o-bottomHeader').addClass('-is-fixed')
    } else {
      $('.v1-o-bottomHeader').removeClass('-is-fixed')
    }
  })
}

/**
 * Will remove these events regardelss of vewport size or if the 'hanburger' menu is open.
 *
 * @discription Helper function to unbind all keydown listeners for the last menu item, and the last item in any following submenu.
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 */
function unbindLastMenuItemKeydown() {
  $('#topNavBar .v01-o-navBar__menu > li:last-child li:last-child a').unbind(
    'keydown'
  )
  $('#topNavBar .v01-o-navBar__menu li:last-child').unbind('keydown')
}

/**
 * @discription Helper function to target the nav submenu link elements and set thier tabindex value
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 * @param {string} x the tabindex
 */
function navSubMenusTabindex(x) {
  $('.v1-m-navContainer__list a').attr('tabindex', x)
}

/**
 * @discription Helper function to target the nav top-level menu link elements and set thier tabindex value
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 * @param {string} x the tabindex
 */
function navTopMenusTabindex(x) {
  $('.v01-o-navBar__menu li a').attr('tabindex', x)

  // console.log("TOP LEVEL nav menu links tabindex: " + x);
}

/**
 * @discription  Helper function to target the login menu link elements and set thier tabindex value
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @requires jquery: 'jquery'
 * @requires u-scroll.js
 * @param {string} x the tabindex
 */
function loginLinksTabindex(x) {
  $('#v1-m-login a').attr('tabindex', x)

  // console.log("SUB MENU links tabindex: " + x);
}

$(document).ready(function () {
  const medBrkptLg = window.matchMedia('screen and (min-width: 71.5em)') // SASS $breakpoint-large

  // Top menu sticky scrolling on large viewports
  fixHeaderNav()

  // inital setup of menus conditions

  // Add inital style hooks and aria attributes for dropdowns.
  $('.-is-dropDown').attr('data-role', 'button').attr('aria-expanded', false)
  $('.-is-dropDown').next('.v1-m-navContainer').addClass('-is-jsDropDown')

  // we have js, so initially make submenu items tab inaccessable at all display sizes
  navSubMenusTabindex(-1)

  // Handel click events for top level menus
  menusDropDownsClickHandler()

  // Close submenus when user clicks away from them
  closeSubmenusOnClick()

  // Handel keyboard tab navigation through submenus
  submenuTabNavigation()

  // The hamburger menu open-close
  $('#topNavBarControl').on('click', function () {
    // Open/close hamburger menu
    hamburgerToggle()

    // Prevent tabbing into elements behind the modal only when the hamburger menu is open
    smallViewportMenuModalTabindex()
  })

  // Media Breakpoints
  mediaBreakpointLg(medBrkptLg) // call on inital load
  medBrkptLg.addListener(mediaBreakpointLg) // add listener
})
