/**
 * Instantiate any tab elements
 * // https://gomakethings.com/the-anatomy-of-a-vanilla-javascript-plugin/
 * // https://github.com/cferdinandi/tabby/issues/108
 *
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 */

import '../vendor/tabby-master/dist/js/tabby.polyfills.js'

if (typeof window !== 'undefined' && 'Tabby' in window) {
  // Tabs
  const tabSelectors = document.querySelectorAll('[data-tabs]')
  for (const [i, tabs] of [...tabSelectors].entries()) {
    tabs.setAttribute(`data-tabs-${i}`, '')
    new Tabby(`[data-tabs-${i}]`)
  }
}
