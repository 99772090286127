/**
 * Used in .v1-m-alert badges
 * This script adds a click event listener to button.-is-dismissible dismissible
 * Click then removes the parent element of the button.
 *
 * on the creation of new dismissible elements, dismissibleAlerts() can be called
 * to register the script.
 *
 * TODO: use event delegation on body or window instead of target element.
 *
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @returns void
 */

;(function () {
  window.dismissibleAlerts = function () {
    var dismissAlert = document.querySelectorAll('button.-is-dismissible')
    Array.from(dismissAlert).forEach((dismissible) => {
      dismissible.addEventListener('click', function (event) {
        dismissible.parentNode.remove()
      })
    })
  }
  dismissibleAlerts()
})()
