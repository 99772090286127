// !TODO these should be only loaded on the models landing page
/**
 * Used for the Models Landing Page
 * Depends on globals navgoco, litty
 *
 * @discription Progressively enhance SVG
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 */
$(document).ready(function () {
  const svg = document.querySelector('#v1-m-models-svg')
  const svgButtons = svg.querySelectorAll('a[role="button"]')

  svgButtons.forEach((button) => {
    button.setAttribute('tabindex', '0')
    button.setAttribute('data-lightbox', '')
    button.setAttribute('href', button.getAttribute('data-href'))
  })
  /**
   * Handel clicks for lightbox, and passing custom options to lity for custom close button.
   */
  $(document).on('click', '[data-lightbox]', function (e) {
    const options = {
      esc: true,
      handler: null,
      template: `<div class="lity" role="dialog" tabindex="-1"><div class="lity-wrap" data-lity-close role="document"><div class="lity-loader" aria-hidden="true">Loading...</div><div class="lity-container"><div class="lity-content"></div></div></div></div>`
    }
    lity(e.currentTarget.href.baseVal, options)
  })
})

/**
 * @discription Intialise accordian menu
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 */
$(document).ready(function () {
  // Initialize navgoco with default options
  $('#adrs_models_nav').navgoco({
    caretHtml: '',
    accordion: false,
    openClass: 'open',
    save: true,
    cookie: {
      name: 'navgoco',
      expires: false,
      path: '/'
    },
    slide: {
      duration: 400,
      easing: 'swing'
    },
    // Add Active class to clicked menu item
    onClickAfter: 'active_menu_cb'
  })

  $('#collapseAll').click(function (e) {
    e.preventDefault()
    $('#adrs_models_nav').navgoco('toggle', false)
  })

  $('#expandAll').click(function (e) {
    e.preventDefault()
    $('#adrs_models_nav').navgoco('toggle', true)
  })
})
