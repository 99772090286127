/**
 * Detects if a scrollbar has been attached to the element provided.
 * Usage: $('#my_div1').hasOverflowY();
 *
 * TODO: Remove jQuery dependancy
 *
 * This is used in areas like tables, where we triger overflow styling when the table is too large to fit in the viewport.
 *
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @returns {boolean} true if there's a vertical/horisontal scrollbar, false otherwise.
 */

jQuery.fn.hasOverflowY = function () {
  if ('undefined' !== this) {
    return this.get(0).scrollHeight > this.outerHeight()
  }
}
jQuery.fn.hasOverflowX = function () {
  if ('undefined' !== this) {
    return this.get(0).scrollWidth > this.outerWidth()
  }
}
