/**
 * Simple test for JavaScript to add/remove body class.
 *
 * @author Ben Rush | https://github.com/bnjmnrsh
 * @version 0.0.1
 * @returns void
 */
;(function jsTest() {
  const root = document.getElementsByTagName('html')[0]
  root.className += ' js'
  root.classList.remove('no-js')
})()
